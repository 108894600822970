import React, { useEffect } from "react"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import "react-id-swiper/lib/styles/css/swiper.css"
import { graphql, Link } from "gatsby"
import WebriqForm from "../components/form/form"
import marked from "marked"

import icon2 from "../images/icons/erp.png"
import icon9 from "../images/icons/data.png"

const SpecialtiesPage = ({ data, location }) => {
  const posts = data.allSanityPost.nodes

  useEffect(() => {
    const initializeWidget = () => {
      if (window.elfsightAPI && typeof window.elfsightAPI.init === "function") {
        window.elfsightAPI.init()
      } else {
        setTimeout(initializeWidget, 100)
      }
    }

    const script = document.createElement("script")
    script.src = "https://static.elfsight.com/platform/platform.js"
    script.defer = true
    script.async = true

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <Layout location={location} page="specialties">
      <div className="specialties-page" style={{ paddingTop: "100px" }}>
        <div className="video-hero specialties-heading">
          <Container>
            <Row className="justify-content-center">
              <Col lg={7}>
                <h1 className="hero-title mb-4">Our Specialties</h1>
                <p className="hero-tagline mb-5">
                  We are ERP, CRM, and data recruiting experts.
                </p>
                <div className="d-flex justify-content-center flex-wrap align-items-center">
                  <Link className="white-btn block relative mr-md-2" to="/jobs">
                    Apply Now
                  </Link>
                  <Link className="white-btn block relative" to="/contact-us">
                    Contact PeerSource
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
          <video muted loop autoPlay poster="/assets/specialties-bg.jpg">
            <source
              src="https://res.cloudinary.com/dwzjvi83z/video/upload/v1615007527/video_nd6hyt_z47zrg.mp4"
              type="video/mp4"
            />
            <track src="" kind="captions" srclang="en" label="English"></track>
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="specialties-services">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} md={12} className="mb-5">
              <Link className="specialties-item" to="../technology/ERPCRM">
                <div className="specialties-list-content h-100">
                  <div className="bg-fff">
                    <img
                      src={icon2}
                      className="img-fluid mb-3"
                      alt="Expertise"
                    />
                    <h6 className="text-blue mb-3">ERP + CRM</h6>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="checked-list lead">
                        <li>Implementation Consultant</li>
                        <li>Business Analyst</li>
                        <li>Technical Consultant</li>
                        <li>ERP Developer</li>
                        <li>CRM Developer</li>
                        <li>Integration Analyst</li>
                        <li>Report Developer</li>
                        <li>Project Manager</li>
                        <li>Customer Success Manager</li>
                        <li>Solution Architect</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="checked-list lead">
                        <li>ERP Administrator</li>
                        <li>CRM Administrator</li>
                        <li>Financial Systems Analyst</li>
                        <li>HRIS Systems Analyst</li>
                        <li>SCM Systems Analyst</li>
                        <li>ERP Trainer</li>
                        <li>CRM Trainer</li>
                        <li>Management and Executive Leadership</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
            <Col lg={6} md={12} className="mb-5">
              <Link className="specialties-item" to="../technology/ERPCRM">
                <div className="specialties-list-content h-100">
                  <div className="bg-fff">
                    <img
                      src={icon9}
                      className="img-fluid mb-3"
                      alt="Expertise"
                    />
                    <h6 className="text-blue mb-3">DATA</h6>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <ul className="checked-list lead">
                        <li>Data Analyst</li>
                        <li>Database Developer</li>
                        <li>ETL Developer</li>
                        <li>Data Engineer</li>
                        <li>Data Architect</li>
                        <li>Database Administrator</li>
                        <li>MDM Specialist</li>
                        <li>Business Intelligence Developer</li>
                      </ul>
                    </div>
                    <div className="col-md-6">
                      <ul className="checked-list lead">
                        <li>Data Scientist</li>
                        <li>Artificial Intelligence Engineer</li>
                        <li>Machine Learning Engineer</li>
                        <li>Computer Vision Engineer</li>
                        <li>Prompt Engineer</li>
                        <li>Management and Executive Leadership</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <div className="py-100">
          <Row className="justify-content-center text-center">
            <Col lg={9}>
              <h2>Level Up Your Data or ERP&CRM Career</h2>
              <p>
                Don’t settle for just any job. Elevate your career to new
                heights with the help of PeerSource’s experienced career
                consultants.
              </p>
              <p>
                Whether you’re a seasoned professional ready to step into a
                bigger role, or you’re looking to take your career in an
                exciting new direction, we want to help connect you with the
                data and ERP/CRM opportunities you deserve. Browse our current
                openings and apply!
              </p>
              <Link className="common-btn block relative" to="/jobs">
                See Opportunities
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
      <div className="google-review home-review">
        <Container>
          <div className="section-content">
            <h2 className="new-h2 text-center mb-5">Testimonials</h2>
            <div className="overlay" />
            {/* <div
              className="elfsight-app-af790131-d08d-49db-823a-95924c9ee439"
              data-elfsight-app-lazy
            ></div> */}
            <div
              className="elfsight-app-24dd4562-4da4-42c1-929b-4b3d317e74b4"
              data-elfsight-app-lazy
              // data-embed-id="25369363"
            ></div>
          </div>
        </Container>
        <div className="overlay" />
      </div>
      <div id="news" className="py-100 blog-section">
        <Container className="latestNews">
          <Row className="mt-5 justify-content-center">
            <Col lg={3} md={12} className="mb-5">
              <h2 className="position-relative mb-4">Featured Posts</h2>

              <div className="mb-5">
                {" "}
                <Link to="/news" className="blue-link ">
                  from our blog
                </Link>
              </div>

              <h6>
                Sign-up to be notified <br />
                of new posts?
              </h6>

              <WebriqForm
                name="Subscribe Blog"
                className="webriq-form"
                form_id="7050eedd-8d8f-4ed8-b5de-f00c71644cc8"
              >
                <div class="form-group">
                  <label className="d-none" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    placeholder="Enter Your Email Here..."
                    name="email"
                    id="email"
                    className="form-control"
                  />
                </div>
                <div className="form-group mt-3">
                  <div className="webriq-recaptcha" />
                </div>
                <button className="common-btn border-none" type="submit">
                  Send
                </button>
              </WebriqForm>
            </Col>
            <Col lg={9} md={12}>
              <Row>
                {posts.map((post, index) => (
                  <Col md={6} className="mb-5" key={index}>
                    <Link to={`/${post.slug?.current}`}>
                      <div className="blog-item bg-light">
                        <div
                          className="bg-blog position-relative"
                          style={{
                            backgroundImage: `url(${post.mainImage?.asset?.fluid?.src})`,
                          }}
                        >
                          <div className="blog-date">
                            <p>
                              <i
                                className="fa fa-calendar-o"
                                aria-hidden="true"
                              ></i>{" "}
                              {post.publishedAt}
                            </p>
                          </div>
                        </div>

                        <div className="blog-body">
                          <h3 className="mb-4">{post.title}</h3>
                          <div
                            className="blog-excerpt"
                            dangerouslySetInnerHTML={{
                              __html: marked(post.excerpt),
                            }}
                          />
                        </div>

                        <div className="blog-foot">
                          <Row>
                            <Col md={7}>
                              {post.authors.length !== 0 && (
                                <p>
                                  <i
                                    className="fa fa-user"
                                    ariaHidden="true"
                                    style={{ color: "#00C1C8" }}
                                  ></i>{" "}
                                  {post.authors[0].person.name}
                                </p>
                              )}
                            </Col>
                            <Col md={5} className="text-right">
                              <p>
                                <Link
                                  to={`/${post?.slug?.current}`}
                                  style={{ color: "#00C1C8" }}
                                >
                                  <small>
                                    Read More{" "}
                                    <i
                                      className="fa fa-arrow-right"
                                      ariaHidden="true"
                                    ></i>
                                  </small>
                                </Link>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div id="specialties-tody" className="py-100">
        <Container>
          <Row>
            <Col md={7}>
              <h3>PeerSource: Your Specialized Partner</h3>
              <span>Tackle your job or talent search with us.</span>
              <div className="flex flex-wrap justify-content-between align-items-center">
                <Link
                  className="white-btn border-none mr-md-2"
                  to="/contact-us"
                  style={{ fontSize: "20px" }}
                >
                  Find Talent
                </Link>
                <Link
                  className="white-btn border-none"
                  to="/jobs"
                  style={{ fontSize: "20px" }}
                >
                  Find Work
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default SpecialtiesPage

export const specialtiesPageQuery = graphql`
  query specialtiesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }

    allSanityPost(sort: { fields: publishedAt, order: DESC }, limit: 2) {
      nodes {
        id
        title
        publishedAt(formatString: "MMM-DD-YYYY")
        mainImage {
          asset {
            fluid {
              src
            }
          }
          alt
        }
        excerpt
        slug {
          current
        }
        authors {
          person {
            name
          }
        }
      }
    }
  }
`
